import React from 'react';

import Box from '@/atoms/Box';
import Text from '@/atoms/Text';

import { scale } from '@/styles/utils';

export default ({ projectNumber, title, ...props }) => {
  return (
    <Box $display="flex" py={0.75} {...props}>
      <Text $width={scale(6)} $color="gray">
        NR{projectNumber}
      </Text>

      <Text as="h3">{title}</Text>
    </Box>
  );
};
