import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import RichText from '@/atoms/RichText';

import theme from '@/styles/theme';

const Categories = styled.ul`
  list-style: none;

  li {
    display: inline;

    :not(:last-child)::after {
      content: ', ';
    }
  }
`;

export default ({
  title,
  projectNumber,
  categories,
  introduction,
  ...props
}) => {
  const CategoriesContent = categories && categories.length > 0 && (
    <Categories>
      {categories.map(cat => (
        <Text as="li" t={-1} key={cat.title}>
          {cat.title}
        </Text>
      ))}
    </Categories>
  );

  return (
    <Grid.Container mb={theme.spacing.medium} {...props}>
      <Grid>
        <Grid.Item
          w={[1, 1, 4 / 12]}
          $display="flex"
          $flexDirection="column"
          $justifyContent="space-between"
        >
          <Box as="header" mb={theme.spacing.lhH3}>
            <Heading h={3} as="p" $color="gray">
              NR{projectNumber}
            </Heading>
            <Heading h={3} as="h1">
              {title}
            </Heading>
          </Box>

          {/* Desktop Categories */}
          {CategoriesContent && (
            <Box $color="gray" $display={['none', 'none', 'block']}>
              {CategoriesContent}
            </Box>
          )}
        </Grid.Item>

        <Grid.Item w={[10 / 12, 10 / 12, 6 / 12]} o={[0, 0, 1 / 12]}>
          <RichText t={1} $color="gray" blocksRaw={introduction} />
        </Grid.Item>

        {/* Mobile Categories */}
        {CategoriesContent && (
          <Grid.Item
            w={[10 / 12]}
            mt={theme.spacing.medium}
            $color="gray"
            $display={['block', 'block', 'none']}
          >
            {CategoriesContent}
          </Grid.Item>
        )}
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment projectIntroData on SanityProject {
    title
    projectNumber
    categories {
      title
    }
    introductionRaw: _rawIntroduction
  }
`;
