import React from 'react';
import { graphql } from 'gatsby';

import Default from './Default';
import Highlighted from './Highlighted';

import { getUri } from '@/utils/routing';

export default ({ slug, isHighlighted, ...props }) => {
  const tileProps = {
    uri: getUri(slug.current, 'project'),
    ...props,
  };

  if (isHighlighted) {
    return <Highlighted {...tileProps} />;
  }

  return <Default {...tileProps} />;
};

export const query = graphql`
  fragment projectTileData on SanityProject {
    key: _id
    title
    projectNumber
    slug {
      current
    }
    thumbnail {
      ...imageWithAltData_AR_0_66
    }
    highlightedThumbnail {
      ...imageWithAltData
    }
    thumbnailVideo {
      ...vimeoData
    }
    highlightedThumbnailVideo {
      ...vimeoData
    }
    highlightedText
  }
`;
