import React from 'react';
import { graphql } from 'gatsby';

import Head from '@/components/Head';
import Footer from '@/components/Footer';
import PageContainer from '@/components/PageContainer';
import ProjectIntro from '@/components/ProjectIntro';
import ModulesLoop from '@/components/ModulesLoop';
import RelatedProjects from '@/components/RelatedProjects';

import theme from '@/styles/theme';

export default ({
  data: {
    project: {
      title,
      meta,
      projectNumber,
      categories,
      introductionRaw,
      contentModules,
      relatedProjects,
    },
  },
}) => {
  return (
    <PageContainer>
      <Head title={title} meta={meta} />

      <ProjectIntro
        title={title}
        projectNumber={projectNumber}
        categories={categories}
        introduction={introductionRaw.text}
      />

      <ModulesLoop contentModules={contentModules} />

      {relatedProjects && relatedProjects.length > 0 && (
        <RelatedProjects projects={relatedProjects} mt={theme.spacing.xlarge} />
      )}

      <Footer />
    </PageContainer>
  );
};

export const query = graphql`
  query($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      title
      meta {
        ...metaData
      }
      projectNumber
      categories {
        title
      }
      introductionRaw: _rawIntroduction
      contentModules {
        ...contentModulesData
      }
      relatedProjects {
        ...projectTileData
      }
    }
  }
`;
