import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import Info from './Info';
import VideoPlayer from '@/components/VideoPlayer';
import Image from '@/components/Image';

import theme from '@/styles/theme';

const ProjectLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${theme.colors.black};

  * {
    transition: color 0.1s ease-out;
  }
  :hover *,
  :focus * {
    color: ${theme.colors.black};
  }
`;

export default ({
  title,
  projectNumber,
  thumbnail,
  thumbnailVideo,
  categories,
  uri,
  columnWidth,
  highlightedImageAlignment,
  highlightedThumbnail,
  highlightedThumbnailVideo,
  highlightedText,
  ...props
}) => {
  const [ref, inView] = useInView({
    rootMargin: '-30% 0px -30% 0px',
  });

  return (
    <ProjectLink ref={ref} to={uri} {...props}>
      {thumbnailVideo ? (
        <VideoPlayer
          vimeoVideo={thumbnailVideo}
          autoplay={false}
          play={inView}
          preload
          loop
          muted
          playsInline
        />
      ) : (
        <Image columnWidth={columnWidth} {...thumbnail} />
      )}

      <Info projectNumber={projectNumber} title={title} />
    </ProjectLink>
  );
};
