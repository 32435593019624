import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import ProjectTile from '@/components/ProjectTile';

import theme from '@/styles/theme';

const columnWidthLarge = [1, 1, 1, 2 / 3];
const columnWidthDefault = [1, 1 / 2, 1 / 2, 1 / 3];

const LargeProductTile = styled(ProjectTile)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;

  .gatsby-image-wrapper {
    flex-grow: 1;
  }
`;

export default ({ projects, ...props }) => {
  return (
    <Grid.Container mb={theme.spacing.large} {...props}>
      <Heading h={3} as="h4" mb={[4, 3.5]}>
        Related projects
      </Heading>

      <Grid mb={[-4, -3.5, -3.5, -1.5]}>
        <Grid.Item as="article" w={columnWidthLarge} mb={[4, 3.5, 3.5, 1.5]}>
          <LargeProductTile columnWidth={columnWidthLarge} {...projects[0]} />
        </Grid.Item>

        {projects[1] && (
          <Grid.Item w={[1, 1, 1, 1 / 3]}>
            <Grid>
              <Grid.Item
                as="article"
                w={[1, 1 / 2, 1 / 2, 1]}
                mb={[4, 3.5, 3.5, 1.5]}
              >
                <ProjectTile
                  columnWidth={columnWidthDefault}
                  $display="flex"
                  $flexDirection="column"
                  {...projects[1]}
                />
              </Grid.Item>

              {projects[2] && (
                <Grid.Item
                  as="article"
                  w={[1, 1 / 2, 1 / 2, 1]}
                  mb={[4, 3.5, 3.5, 1.5]}
                >
                  <ProjectTile
                    columnWidth={columnWidthDefault}
                    {...projects[2]}
                  />
                </Grid.Item>
              )}
            </Grid>
          </Grid.Item>
        )}
      </Grid>
    </Grid.Container>
  );
};
