import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import Info from './Info';
import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import Image from '@/components/Image';
import VideoPlayer from '@/components/VideoPlayer';

import theme from '@/styles/theme';

const ProjectLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${theme.colors.black};

  * {
    transition: color 0.1s ease-out;
  }
  :hover *,
  :focus * {
    color: ${theme.colors.black};
  }
`;

export default ({
  title,
  projectNumber,
  thumbnail,
  thumbnailVideo,
  categories,
  uri,
  columnWidth,
  highlightedImageAlignment,
  highlightedThumbnail,
  highlightedThumbnailVideo,
  highlightedText,
  ...props
}) => {
  const [ref, inView] = useInView({
    rootMargin: '-30% 0px -30% 0px',
  });

  const imageOrder = highlightedImageAlignment === 'right' ? 1 : 0;
  const imageWidth =
    highlightedThumbnail?.asset?.fluid.aspectRatio < 1 ? 7 / 12 : 8 / 12;
  const contentOffset = imageOrder === 0 && imageWidth === 7 / 12 ? 1 / 24 : 0;
  const imageColumns = [1, 1, 1, imageWidth];

  // art directed image
  const { asset, ...imageProps } = thumbnail;
  let artDirectedAsset;

  if (
    highlightedThumbnail?.asset &&
    highlightedThumbnail.asset.fluid.src !== asset.fluid.src
  ) {
    artDirectedAsset = [
      {
        ...highlightedThumbnail.asset,
        media: `(min-width: ${theme.breakpoints[2]})`,
      },
      asset,
    ];
  } else {
    artDirectedAsset = asset;
  }

  return (
    <Grid ref={ref} {...props}>
      <Grid.Item w={imageColumns} $order={[0, 0, 0, imageOrder]}>
        <ProjectLink to={uri}>
          {highlightedThumbnailVideo ? (
            <VideoPlayer
              vimeoVideo={highlightedThumbnailVideo}
              autoplay={false}
              play={inView}
              preload
              loop
              muted
              playsInline
            />
          ) : (
            <Image
              columnWidth={imageColumns}
              asset={artDirectedAsset}
              {...imageProps}
            />
          )}
        </ProjectLink>
      </Grid.Item>

      <Grid.Item w={[1, 1, 1, 4 / 12]} o={[0, 0, 0, contentOffset]}>
        <ProjectLink to={uri}>
          {highlightedText && (
            <Text t="h3" pb={2.25} $display={['none', 'none', 'none', 'block']}>
              {highlightedText}
            </Text>
          )}

          <Info projectNumber={projectNumber} title={title} />
        </ProjectLink>
      </Grid.Item>
    </Grid>
  );
};
